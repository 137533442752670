<template>
  <div>

    <CRow>
      <CCol lg="12">
        <h1 class="main-header" v-translate translate-context="impersonate">
          Users List
        </h1>
        <CCard class="">
          <CCardBody class="px-0">
            <CDataTable
                :hover="false"
                :bordered="true"
                :items="users.results"
                :fields="fields"
                :column-filter="{external: true,lazy:true}"
                :column-filter-value="filters"
                :sorter="{external: true, resetable:true}"
                :loading="loading"
                :sorter-value="sorting"
                @update:column-filter-value="updateFilters"
                @update:sorter-value="updateSorting"
                class="custom-table custom-table--clean custom-table--has-filters"
            >
              <template #no-items-view>
                <div class="text-center">
                  <h5 v-translate>No items found</h5>
                </div>
              </template>
              <template #sorting-icon="{ state, classes }">
                <div :class="classes">
                  <CIcon name="cipCaretUp" size="lg" v-if="state === 'asc' || state === 0"></CIcon>
                  <CIcon name="cipCaretUp" size="lg" v-else></CIcon>
                </div>
              </template>
              <template #actions="{item}">
                <td class="text-right">
                  <CButton variant="outline" color="success" class="mr-2"
                           @click="doImpersonate(item)"
                           v-translate translate-context="impersonate.actions"
                  >
                    Impersonate
                  </CButton>
                </td>
              </template>
              <template #under-table>
                <CPagination
                    v-show="users.pagination.num_pages > 1"
                    :activePage="users.pagination.page"
                    @update:activePage="updatePage"
                    :pages="users.pagination.num_pages"
                />
              </template>
            </CDataTable>
          </CCardBody>
        </CCard>
      </CCol>


    </CRow>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'ListEventTemplate',

  data() {
    return {
      loading: true,
      fields: [
        {key: 'id', label: this.$pgettext('impersonate.list','ID'), _style: 'width:100px;'},
        {key: 'first_name', label: this.$pgettext('impersonate.list','First Name')},
        {key: 'last_name', label: this.$pgettext('impersonate.list','Last Name')},
        {key: 'email', label: this.$pgettext('impersonate.list','E-Mail')},
        {key: 'actions', label: this.$pgettext('event_template.list','Actions'), sorter: false, filter: false}
      ],
      pagination: {
        page_size: 20,
        page: 1,
      },
      sorting: {
        column: null,
        asc: false
      },
      filters: {},
    }
  },
  async mounted() {
    await this.fetchList()
  },
  computed: {
    ...mapGetters('impersonate', [
      'users',
    ]),
  },
  methods: {
    ...mapActions('impersonate', [
      'fetchUsersList',
      'impersonate'
    ]),
    async fetchList() {
      this.loading = true
      await this.fetchUsersList({
        filters: this.filters,
        pagination: this.pagination,
        sorting: this.sorting
      })
      this.pagination.page = this.users.pagination.page
      this.loading = false
    },
    async doImpersonate(user) {
      await this.impersonate(user)
      await this.$router.push({name: 'eventList'})
    },
    updateFilters(filters) {
      this.filters = filters
      this.pagination.page = 1
      this.fetchList()
    },
    updateSorting(sorting) {
      this.sorting = sorting
      this.fetchList()
    },
    updatePage(page) {
      this.pagination.page = page
      this.fetchList()
    },
  }
}
</script>

<style scoped>

</style>